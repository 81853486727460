import logger from 'itrvl-logger';
import { cloneDeep, map, reduce, get } from 'lodash';
import Dinero from 'dinero.js';

const log = logger(__filename);

function isFloat(value) {
  return !isNaN(value) && parseFloat(value) === value && value % 1 !== 0;
}

// The correct currency precision for various currencies
// Data collected from:
// https://developer.cybersource.com/library/documentation/sbc/quickref/currencies.pdf
export const getCurrencyPrecision = currency => {
  switch (currency) {
    case 'BHD':
    case 'IQD':
    case 'JOD':
    case 'KWD':
    case 'LYD':
    case 'OMR':
    case 'TND':
      return 3;
    case 'BIF':
    case 'BYR':
    case 'CLP':
    case 'DJF':
    case 'GNF':
    case 'GWP':
    case 'ISK':
    case 'JPY':
    case 'KMF':
    case 'KRW':
    case 'MGA':
    case 'PYG':
    case 'RWF':
    case 'VND':
    case 'VUV':
    case 'XAF':
    case 'XOF':
    case 'XPF':
      return 0;
    case 'CLF':
      return 4;
    default:
      return 2;
  }
};

const Money = o => {
  try {
    // Handle no options given
    if (!o) {
      o = {};
    } else {
      o = cloneDeep(o);
    }

    // Allow construction using unit instead of amount and round it, since we receive some values in unit
    if (o.unit) {
      // Window hands us this as a cost sometimes
      if (o.unit === 'N/A') {
        o.amount = 0;
      } else {
        o.amount = Math.round(Number(o.unit) * Math.pow(10, getCurrencyPrecision(o.currency || Dinero.defaultCurrency)));
      }
      delete o.unit;
    }

    // Assign the correct precision for the currency if not already requested
    if (!o.precision) {
      o.precision = getCurrencyPrecision(o.currency);
    }

    if (o.currency === undefined || typeof o.currency !== 'string' || o.currency.length !== 3) {
      throw new Error('Invalid currency: ' + o.currency + ' ' + typeof o.currency);
    }

    if (isFloat(o.amount)) {
      log.warn('float applied to amount, check your math?', JSON.stringify(o));
    }

    const dineroInstance = Dinero(o);

    return {
      ...dineroInstance,
      toFormat: formatString => {
        // dinero assumes everything is precision 2, even if you change the precision to something else
        // when you call .toFormat on something with precision 0, you'll still get decimals
        // eg. Dinero({ amount: 97000, currency: 'JPY', precision: 0 }).toFormat() you get ¥97,000.00
        // This will override .toFormat, catch precision === 0, and auto change the format to exclude cents
        let format;
        if (formatString) {
          format = formatString;
        } else if (dineroInstance.getPrecision() === 0) {
          format = '$0,0';
        }
        return dineroInstance.toFormat(format);
      },
    };
  } catch (err) {
    log.error('unable to create a valid Dinero instance', err);
    return Dinero({ amount: 0, currency: o.currency ?? 'USD' });
  }
};

// Copy all other methods onto our Money
Object.assign(Money, Dinero);

Money.exchangeSync = (dinero, targetCurrency, customRates = {}) => {
  if (!dinero) {
    throw new Error('Dinero required');
  }
  if (!targetCurrency || targetCurrency === undefined || typeof targetCurrency !== 'string') {
    log.debug('INVALID CURRENCY');
    throw new Error('Currency required');
  }

  if (dinero.getCurrency() === undefined) {
    log.error('INVALID DINERO CURRENCY');
    throw new Error('Dinero got an invalid currency');
  }

  const currency = dinero.getCurrency();

  // Short circuit no conversion
  if (currency === targetCurrency) {
    return dinero;
  }

  /**
      this expects a data structure of:
      {
        [currency]: {
          [currency1]: exchangeRate1,
          [currency2]: exchangeRate2,
          // ... other currencies in the exchange
        }
      }
  */
  let rate = get(customRates, `${currency}.${targetCurrency}`);
  if (typeof rate === 'undefined') {
    const inverse = get(customRates, `${targetCurrency}.${currency}`);
    if (typeof inverse == 'undefined') {
      throw new Error(`Custom Rates Missing Target: ${currency}.${targetCurrency}: ` + JSON.stringify(customRates));
    }
    rate = 1 / inverse;
  }

  const targetPrecision = getCurrencyPrecision(targetCurrency);
  const convertedAmount = dinero.getAmount() * rate;
  const normalizedAmount = convertedAmount / Math.pow(10, getCurrencyPrecision(currency) - targetPrecision);

  return Dinero({ amount: Math.round(normalizedAmount), currency: targetCurrency });
};

// @todo: this is not used in the code base... so it should probably be removed?
// Custom exchange function to make it easy for us to use our rates and itinerary exchanges
// TODO: This could be dropped for a global exchangeFactory if Dinero merges my PR
// See: https://github.com/dinerojs/dinero.js/pull/158
Money.exchange = async (dinero, currency, customRates = {}) => {
  if (!dinero) {
    throw new Error('Dinero required');
  }
  if (!currency || currency == undefined || typeof currency !== 'string') {
    log.debug('INVALID CURRENCY');
    throw new Error('Currency required');
  }

  if (dinero.getCurrency() == undefined) {
    log.error('INVALID DINERO CURRENCY');
    throw new Error('Dinero got an invalid currency');
  }

  // Short circuit no conversion
  if (dinero.getCurrency() == currency) {
    return dinero;
  }
  const forwardRates = await Money.getExchangeRates(dinero.getCurrency());
  const convertOptions = { propertyPath: '{{to}}', endpoint: new Promise(resolve => resolve(forwardRates.rates)) };
  return await dinero.convert(currency, convertOptions);
};

Money.withAccumulate = () => {
  const costs = {};
  const addCost = amount => {
    const currency = amount.getCurrency();
    if (!costs[currency]) {
      costs[currency] = Money({ currency });
    }
    costs[currency] = costs[currency].add(amount);
  };
  return [costs, addCost];
};

Money.toAccumulated = (...buckets) => {
  const [total, addTotal] = Money.withAccumulate();
  map(buckets, bucket => {
    map(bucket, (amount, currency) => {
      addTotal(Money({ amount, currency }));
    });
  });
  return total;
};

Money.fromAccumulated = (...accumulated) => {
  const [total, addTotal] = Money.withAccumulate();
  map(accumulated, (amounts, currency) => {
    map(amounts, amount => {
      addTotal(amount);
    });
  });
  return reduce(
    total,
    (ret, amount, currency) => {
      ret[currency] = amount.getAmount();
      return ret;
    },
    {},
  );
};

Money.reduceToSingleCurrency = async (currency, customRates, ...amounts) => {
  let total = Money({ currency });
  for (const i in amounts) {
    const amount = amounts[i];
    for (const j in amount) {
      const amt = amount[j];
      if (amt.getCurrency() !== currency) {
        total = total.add(await Money.exchange(amt, currency, customRates));
      } else {
        total = total.add(amt);
      }
    }
  }
  return total;
};

Money.reduceToSingleCurrencySync = (currency, customRates, ...amounts) => {
  let total = Money({ currency });
  for (const i in amounts) {
    const amount = amounts[i];
    for (const j in amount) {
      const amt = amount[j];
      if (amt.getCurrency() !== currency) {
        total = total.add(Money.exchangeSync(amt, currency, customRates));
      } else {
        total = total.add(amt);
      }
    }
  }
  return total;
};

Money.getExchangeRates = () => {
  throw new Error('Exchange Not Initialized');
};

Money.setupExchangeRates = implementation => {
  if (implementation) {
    Money.getExchangeRates = implementation;
  }
};

export default Money;
