import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';
import { keyBy } from 'lodash';
import { useUser } from 'common/hooks/user';
import { getAgencyIdForPatch } from './utils';

export const useCampsInfoMapQuery = ({ instance, enabled }) => {
  const Api = useApi();
  return useQuery({
    queryKey: ['camp-info-map', instance?.id],
    queryFn: async () => {
      const accommodations = instance.accommodationsFromSegments;
      const response = await Api.getCamp({
        where: {
          supplierCode: {
            in: (instance?.itinerary?.params?.accommodations || accommodations).map(accommodation => accommodation.supplierCode),
          },
        },
        fields: ['supplierCode', 'campName', 'regionName', 'campInfo', 'country', 'featuredImageId', 'dmcArrangedOnly'],
        include: {
          relation: 'featuredImage',
          scope: {
            fields: ['s3Key'],
          },
        },
      });
      return keyBy(response?.data || [], 'supplierCode');
    },
    staleTime: Number.Infinity,
    enabled,
  });
};

export const useCampInfoQuery = ({ id, enabled = true }) => {
  const Api = useApi();
  return useQuery({
    queryKey: ['camp-info', id],
    queryFn: async () => {
      const response = await Api.getCampInfo(id);
      return response?.data || [];
    },
    staleTime: Number.POSITIVE_INFINITY,
    enabled,
  });
};

export const useCampInfoEditsQuery = ({ id, enabled = true }) => {
  const Api = useApi();
  return useQuery({
    queryKey: ['camp-info-edits', id],
    queryFn: async () => {
      const response = await Api.getCampInfoEdits(id);
      return response?.data || [];
    },
    staleTime: Number.POSITIVE_INFINITY,
    enabled,
  });
};
//TODO - upsert is taking the value of where and merging with results on upsert and patch
export const useCampEditsUpsert = props => {
  const Api = useApi();
  const { user } = useUser();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, payload }) => {
      const response = await Api.upsertCampInfoEdits(id, {
        ...payload,
        agencyId: getAgencyIdForPatch(user),
      });
      await queryClient.invalidateQueries({ queryKey: ['camp', 'list'], refetchType: 'all' });
      return response?.data || [];
    },
    onSuccess: data => {
      props?.onSuccess?.(data);
    },
    onError: () => {
      props?.onError?.();
    },
  });
};
export const useCampEditsPatch = props => {
  const Api = useApi();
  const { user } = useUser();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, payload }) => {
      await Api.patchCampInfoEdits(id, {
        ...payload,
        agencyId: getAgencyIdForPatch(user),
      });
      await queryClient.invalidateQueries({ queryKey: ['camp', 'list'], refetchType: 'all' });
      return true;
    },
    onSuccess: data => {
      props?.onSuccess?.(data);
    },
    onError: () => {
      props?.onError?.();
    },
  });
};

export const useCampAssemblerPut = props => {
  return useMutation({
    mutationFn: async ({ s3PresignedUrl, file }) => {
      await axios.put(s3PresignedUrl, file, {
        headers: { 'Content-Type': file.type },
      });
    },
    onSuccess: data => {
      props?.onSuccess?.(data);
    },
    onError: () => {
      props?.onError?.();
    },
  });
};

export const useCampsListQuery = ({ filters = {}, enabled = true }) => {
  const Api = useApi();
  return useQuery({
    queryKey: ['camp', 'list', filters],
    queryFn: async () => {
      const response = await Api.getAgencyCampsList(filters);
      return response?.data ?? [];
    },
    retry: (_failureCount, error) => {
      if (error?.response?.status >= 400) return false;
      return 3;
    },
    cacheTime: 0,
    enabled,
    keepPreviousData: true,
  });
};

export const useCampsAssemblerVideo = id => {
  const Api = useApi();
  return useQuery({
    queryKey: ['camp-assembler-video', id],
    queryFn: async () => {
      const response = await Api.getCampAssemblerVideo(id);
      return response?.data ?? {};
    },
  });
};
