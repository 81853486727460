import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import Tooltip from 'components/v2/Tooltip';

import PlusCircleIcon from './icons/PlusCircle';
import MinusCircleIcon from './icons/MinusCircle';

import { useBuilderStore } from './store';
import { usePrefetchMonth, useSupplierBounds } from './hooks';
import { OWN_ACCOMMODATION_SUPPLIER_CODE } from 'itrvl-types';

const useStyles = makeStyles({
  root: {},
  pin: {
    // color: '#123438',
    width: 17,
    height: 17,
    borderRadius: '50%',
    position: 'relative',
    cursor: 'pointer',
    flexShrink: 0,
    '& svg': {
      position: 'relative',
      zIndex: 2,
      width: '100%',
      height: 'auto',
    },
    '& .pin-back': {
      backgroundColor: '#fff',
      position: 'absolute',
      zIndex: 1,
      width: '50%',
      height: '50%',
      borderRadius: '50%',
      transform: 'translate(50%, 50%)',
    },
    '&.pinned': {
      color: '#123438',
      '& .pin-back': {
        backgroundColor: '#fff',
      },
    },
  },
});

const PinAccommodation = props => {
  const classes = useStyles();
  const { supplierCode } = props;
  const pinned = useBuilderStore(state => supplierCode in state.data.pinnedAccommodationMap);
  const toggleAccommodationPin = useBuilderStore(state => state.actions.accommodations.toggleAccommodationPin);
  // maybe quote only here
  const prefetchMonth = usePrefetchMonth(supplierCode, true);
  const { date } = useSupplierBounds(supplierCode);

  return (
    <Tooltip title={`${pinned ? 'Unpin' : 'Pin'} Accommodation`} placement="top">
      <div
        data-test-id={`${pinned ? 'unpin' : 'pin'}-accommodation-button`}
        className={clsx(classes.pin, pinned && 'pinned')}
        onClick={() => {
          toggleAccommodationPin(props);
          if (supplierCode !== OWN_ACCOMMODATION_SUPPLIER_CODE) {
            prefetchMonth(date, false);
          }
        }}
      >
        <div className="pin-back" />
        {pinned ? <MinusCircleIcon /> : <PlusCircleIcon />}
      </div>
    </Tooltip>
  );
};

export default PinAccommodation;
