import React, { useState, useCallback, useRef, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { map, isEmpty, sumBy, toArray, debounce, size, reduce } from 'lodash';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { useTheme } from '@mui/material/styles';
import { useApi } from 'common/hooks/api';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ClickAwayListener, Fab } from '@mui/material';
import SearchBar from './SearchBar';
import Image from 'common/components/Image';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SearchIcon from '@mui/icons-material/Search';

import { Person } from '@mui/icons-material';
import ItrvlIcon2 from 'common/components/Icons/ItrvlIcon2';

import logger from 'itrvl-logger';

const log = logger(__filename);
log.trace(__filename);

const GUTTER = 18;
const styles = theme => ({
  root: {
    margin: '0 1.5rem',
    fontFamily: 'Atyp Display',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  link: {
    color: '#221F20',
    '&:not(.no-hover)': {
      transition: theme.transitions.create(['background-color']),
      '&:hover': {
        backgroundColor: '#F8F7F5',
      },
    },
  },
  floater: {
    position: 'relative',
    zIndex: 1,
  },
  results: {
    position: 'absolute',
    width: '100%',
    backgroundColor: 'white',
    color: 'black',
    marginTop: 9,
  },
  resultsBlock: {
    display: 'flex',
    flexDirection: 'column',
    padding: GUTTER,
    border: '1px solid #EEEBE5',
    marginBottom: -1,
  },
  resultType: {},
  resultTypeHeader: {
    textTransform: 'capitalize',
    gridColumn: 1,
  },
  client: {
    whiteSpace: 'nowrap',
    display: 'grid',
    placeItems: 'center',
    gridTemplateColumns: 'repeat(6,1fr)',
    padding: '5px 0',
    '& > div': {
      width: '100%',
      gridColumn: 'span 4',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&:nth-child(2n-0)': {
        gridColumn: 'span 2',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  itinerary: {
    padding: '0.5rem 0',
    borderBottom: '1px solid #F8F7F5',
    whiteSpace: 'nowrap',
    display: 'grid',
    placeItems: 'center',
    grid: 'auto / repeat(6,1fr)',
    gap: 5,
    '& > div': {
      gridColumn: 'span 2',
      width: '100%',
      '&:nth-child(4n-0)': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '&:nth-child(4n-1)': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '&:nth-child(4n-3)': {
        color: '#123438',
        gridColumn: 'span 6',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    textTransform: 'capitalize',
    borderBottom: '2px solid #F8F7F5',
    fontSize: '1rem',
    lineHeight: '1.875rem',
    paddingBottom: 8,
    marginBottom: 8,
  },
  viewAll: {
    textTransform: 'uppercase',
    textAlign: 'right',
    fontSize: '0.75rem',
    marginTop: 10,
    letterSpacing: 0.2,
  },
  arrow: {
    fontSize: '1rem',
    verticalAlign: 'sub',
  },
  noResults: {
    textAlign: 'center',
  },
  iconQuoteId: {
    verticalAlign: 'sub',
    fontSize: '1rem',
    marginRight: '.2rem',
  },
  iconClient: {
    fontSize: '1em',
    marginRight: '.2rem',
    color: '#123438',
    fontWeight: 300,
    strokeWidth: 0.1,
    position: 'relative',
    top: 2,
  },
  searchBar: {
    width: `calc(${placeholder.length * 1.5}ch)`,
    height: 40,
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  headshot: {
    height: '1.625rem',
    width: '1.625rem',
    flexShrink: 0,
    position: 'relative',
    //fontSize: 0, // Hide alt text on missing img.
    '& > img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
    },
  },
  agent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    '& > span': {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },

  desktop: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  mobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },

  fabLabel: {
    display: 'flex',
  },

  input: {
    padding: 0,
  },
});

const placeholder = 'Search for Clients or Itineraries';

const TYPE = { CLIENTS: 'clients', ITINERARIES: 'itineraries' };
const debounceTime = 200;
export { debounceTime };

export default function GlobalSearch() {
  const Api = useApi();
  const theme = useTheme();
  const classes = makeStyles(styles, { name: GlobalSearch.name })();
  const [results, setResults] = useState({});
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const LIMIT = 5;
  const limitPlus = length => {
    if (length < LIMIT) return length;
    return `${LIMIT}+`;
  };

  const refTerm = useRef(); // undefined
  const handleSearch = useMemo(
    () =>
      debounce(async eOrValue => {
        const term = eOrValue?.target?.value ?? eOrValue;
        if (term !== refTerm.current) {
          refTerm.current = term;
          const results = await Api.search({ term, limit: LIMIT + 1 });
          if (term === refTerm.current) {
            setResults(results);
          }
        }
      }, debounceTime),
    [Api],
  );
  const handleCancel = async () => {
    handleSearch('');
  };

  const onLink = () => {
    setResults({});
    refTerm.current = undefined;
  };

  const toAll = useCallback(type => {
    switch (type) {
      case TYPE.CLIENTS:
        return '/clients';
      case TYPE.ITINERARIES:
        return '/trips-and-payments';
      default:
        return false;
    }
  }, []);

  const filteredResults = useMemo(
    () =>
      reduce(
        results,
        (acc, value, key) => {
          if (size(value) !== 0) {
            acc[key] = value;
          }
          return acc;
        },
        {},
      ),
    [results],
  );

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.desktop}>
          <SearchBar
            align="right"
            placeholder={matches ? 'Search' : placeholder}
            className={classes.searchBar}
            onCancelSearch={handleCancel}
            onFocus={handleSearch}
            onKeyUp={handleSearch}
            idPrefix={'globalSearch'}
            inputProps={{ role: 'searchbox', 'aria-label': 'global search', classes: { input: classes.input } }}
          />
        </div>

        <Fab className={classes.mobile} aria-label="search" size="small" classes={{ label: classes.fabLabel }}>
          <SearchIcon />
        </Fab>
      </div>

      {!isEmpty(results) && (
        <ClickAwayListener onClickAway={() => setResults({})}>
          <div className={classes.floater}>
            <div className={classes.results} data-test-id="search-results-container">
              {!sumBy(toArray(results), 'length') && (
                <div className={classes.resultsBlock}>
                  <div className={classes.noResults} data-test-id="no-search-results">
                    No matches found.{' '}
                    <a href="https://itrvl.helpjuice.com" target="_blank" rel="noopener noreferrer">
                      Need some help?
                    </a>
                  </div>
                </div>
              )}

              {map(filteredResults, (resultType, type, _collection) => {
                return (
                  <div className={classes.resultsBlock} key={type} data-test-id={`${type}-search-results-container`}>
                    <div className={classes.title} role="heading" aria-level={1} aria-label={type}>
                      {type} ({limitPlus(resultType.length)})
                    </div>
                    <div className={classes.grid} data-test-id={`${type}-search-results-list`}>
                      {map(resultType.slice(0, LIMIT), result => (
                        <React.Fragment key={`${type}-${result.id}`}>
                          {type === TYPE.CLIENTS && (
                            <Link to={`/clients/${result.id}`} onClick={onLink} className={classes.link}>
                              <div className={classes.client} role="listitem" aria-label={result.name}>
                                <div>{result.name}</div>
                                <div className={classes.agent}>
                                  <div className={classes.headshot}>
                                    {result.agentHeadshotUri ? <Image alt="headshot" src={result.agentHeadshotUri} /> : <Person />}
                                  </div>
                                  <span>{result.agentFullName}</span>
                                </div>
                              </div>
                            </Link>
                          )}
                          <div className={classes.spacer} />
                          {type === TYPE.ITINERARIES && (
                            <Link
                              to={`/itinerary/${result.id}`}
                              onClick={onLink}
                              className={classes.link}
                              data-test-id={`itinerary-${result.id}`}
                            >
                              <div className={classes.itinerary} role="listitem" aria-label={result.name}>
                                <div data-test-id="itinerary-name">{result.name}</div>
                                <div>
                                  <ItrvlIcon2 name="fileInvoice" className={classes.iconClient} />#{result.quoteId}
                                </div>
                                <div>
                                  <ItrvlIcon2 name="user" className={classes.iconClient} />
                                  {result.clientName}
                                </div>
                                <div className={classes.agent}>
                                  <div className={classes.headshot}>
                                    {result.agentHeadshotUri ? <Image alt="headshot" src={result.agentHeadshotUri} /> : <Person />}
                                  </div>
                                  <span>{result.agentFullName}</span>
                                </div>
                              </div>
                            </Link>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                    <div className={classes.viewAll}>
                      <Link to={toAll(type)} onClick={onLink} className={clsx(classes.link, 'no-hover')}>
                        View all {type} <ArrowForwardIcon className={classes.arrow} />
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
}
