export default function getPresentationEditEndpoints(build) {
  return {
    getPresentationEditByItinerary: build.query({
      query: itineraryId => {
        const filter = {
          where: {
            itineraryId: itineraryId,
          },
        };
        return `/PresentationEdits/?filter=${JSON.stringify(filter)}`;
      },
      providesTags: ['PresentationEdit'],
    }),
    getPresentationEditByClient: build.query({
      query: clientId => {
        const filter = {
          where: {
            clientId: clientId,
          },
        };
        return `/PresentationEdits/?filter=${JSON.stringify(filter)}`;
      },
      providesTags: ['PresentationEdit'],
    }),
    createOrUpdatePresentationEdit: build.mutation({
      queryFn: async (updateObj, _queryApi, _extraOptions, baseQuery) => {
        const { data } = await baseQuery({
          url: `/PresentationEdits/createOrUpdate`,
          method: 'POST',
          body: updateObj,
        });
        if (updateObj.video) {
          await baseQuery({
            url: `/Itineraries/${updateObj.itineraryId}/assembleVideo`,
            method: 'POST',
          });
        }
        return { data };
      },
      invalidatesTags: ['PresentationEdit'],
    }),
    postPresentationEdit: build.mutation({
      query: updateObj => ({
        url: `/PresentationEdits`,
        method: 'POST',
        body: updateObj,
      }),
      invalidatesTags: ['PresentationEdit'],
    }),
    patchPresentationEdit: build.mutation({
      query: updateObj => ({
        url: `/PresentationEdits/${updateObj.id}`,
        method: 'PATCH',
        body: updateObj,
      }),
      invalidatesTags: ['PresentationEdit'],
    }),
    renderDataItinerary: build.query({
      query: id => `/PresentationEdits/renderDataItinerary?itineraryId=${id}&includeAllImages=1`,
      providesTags: ['PresentationEdit'],
    }),
  };
}
