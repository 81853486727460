import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useApi } from 'common/hooks/api';

export const useGetDmcByCountryCurrencyQuery = ({ countryCode, currency, onSuccess }) => {
  const Api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery({
    queryKey: ['dmc', { countryCode, currency }],
    queryFn: async () => {
      return (await Api.getDmcByCountryCurrency({ countryCode, currency }))?.data;
    },
    onSuccess,
    onError: err => enqueueSnackbar(err?.response?.data?.error?.message, { variant: 'error' }),
    cacheTime: 0,
    staleTime: 0,
  });
  return query;
};

export const useGetDmcQuery = () => {
  const Api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery({
    queryKey: ['dmc'],
    queryFn: async () => {
      return await Api.getDmc();
    },
    onSuccess: () => {},
    onError: err => enqueueSnackbar(err?.response?.data?.error?.message, { variant: 'error' }),
  });
  return query;
};
