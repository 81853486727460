import logger from 'itrvl-logger';
import moment from 'moment';
import { isUndefined, get, size, find } from 'lodash';

import { hasItineraryFlag, getItineraryFlag, ITINERARY_FLAG } from './itineraryFlag';
import { PAYMENT_STATUS } from './payment';

const log = logger(__filename);

export const ITINERARY_STATE = {
  // TODO: #2901 Reduce these three to just one as they all mean the same thing
  // This quote is just an example
  EXAMPLE: 'example',
  // The quote is duplicated and has prior pricing, but has not been requoted by wilderness
  ESTIMATE: 'estimate',
  // Itinerary has been saved?
  SAVED: 'saved',
  // Itinerary is a draft?
  DRAFT: 'draft',
  // We are requesting a quote from wilderness
  QUOTING: 'quoting',
  // We have a quote from wilderness
  QUOTE: 'quote',
  // We are requesting a hold from wilderness
  HOLDING: 'holding',
  HOLD_REQUESTED: 'hold_requested',
  // Itinerary is being provisionally held by wilderness
  PROVISIONAL: 'provisional',
  // Client has authorized deposit and agent needs to confirm
  CONFIRM_REQUESTED: 'confirm_requested',
  // We have requested confirmation from wilderness and await their result
  CONFIRMING: 'confirming',
  // The itinerary is confirmed with wilderness
  CONFIRMED: 'confirmed',
  // Wilderness told us the state was 'Pending'
  // Not sure if this comes on request hold or request confirm
  UNKNOWN: 'unknown',
  CANCELLED: 'cancelled',
};

export const itineraryStateEditDmc = state => {
  switch (state) {
    case ITINERARY_STATE.EXAMPLE:
    case ITINERARY_STATE.ESTIMATE:
    case ITINERARY_STATE.SAVED:
    case ITINERARY_STATE.DRAFT:
    case ITINERARY_STATE.QUOTING:
    case ITINERARY_STATE.QUOTE:
    case ITINERARY_STATE.HOLDING:
    case ITINERARY_STATE.PROVISIONAL:
    case ITINERARY_STATE.CONFIRM_REQUESTED:
    case ITINERARY_STATE.CONFIRMING:
    case ITINERARY_STATE.CONFIRMED:
      return true;
    case ITINERARY_STATE.UNKNOWN:
    case ITINERARY_STATE.CANCELLED:
      return false;
    default:
      log.warn('Unknown itinerary state:', state);
      return false;
  }
};

export const itineraryStateEditTransit = state => {
  switch (state) {
    case ITINERARY_STATE.EXAMPLE:
    case ITINERARY_STATE.ESTIMATE:
    case ITINERARY_STATE.SAVED:
    case ITINERARY_STATE.DRAFT:
    case ITINERARY_STATE.QUOTING:
    case ITINERARY_STATE.QUOTE:
    case ITINERARY_STATE.HOLDING:
      return true;
    case ITINERARY_STATE.PROVISIONAL:
    case ITINERARY_STATE.CONFIRM_REQUESTED:
    case ITINERARY_STATE.CONFIRMING:
    case ITINERARY_STATE.CONFIRMED:
    case ITINERARY_STATE.UNKNOWN:
    case ITINERARY_STATE.CANCELLED:
      return false;
    default:
      log.warn('Unknown itinerary state:', state);
      return false;
  }
};

export const itineraryStateEditActivities = state => {
  switch (state) {
    case ITINERARY_STATE.EXAMPLE:
    case ITINERARY_STATE.ESTIMATE:
    case ITINERARY_STATE.SAVED:
    case ITINERARY_STATE.DRAFT:
    case ITINERARY_STATE.QUOTING:
    case ITINERARY_STATE.QUOTE:
    case ITINERARY_STATE.HOLDING:
    case ITINERARY_STATE.PROVISIONAL:
    case ITINERARY_STATE.CONFIRM_REQUESTED:
    case ITINERARY_STATE.CONFIRMING:
    case ITINERARY_STATE.CONFIRMED:
      return true;
    case ITINERARY_STATE.UNKNOWN:
    case ITINERARY_STATE.CANCELLED:
      return false;
    default:
      log.warn('Unknown itinerary state:', state);
      return false;
  }
};

export const itineraryStateModifyBednights = state => {
  switch (state) {
    case ITINERARY_STATE.EXAMPLE:
    case ITINERARY_STATE.ESTIMATE:
    case ITINERARY_STATE.SAVED:
    case ITINERARY_STATE.DRAFT:
    case ITINERARY_STATE.QUOTING:
    case ITINERARY_STATE.QUOTE:
    case ITINERARY_STATE.HOLDING:
    case ITINERARY_STATE.PROVISIONAL:
      return true;
    case ITINERARY_STATE.CONFIRM_REQUESTED:
    case ITINERARY_STATE.CONFIRMING:
    case ITINERARY_STATE.CONFIRMED:
    case ITINERARY_STATE.UNKNOWN:
    case ITINERARY_STATE.CANCELLED:
      return false;
    default:
      log.warn('Unknown itinerary state:', state);
      return false;
  }
};

export const itineraryStateCanPay = state => {
  switch (state) {
    case ITINERARY_STATE.EXAMPLE:
    case ITINERARY_STATE.SAVED:
    case ITINERARY_STATE.DRAFT:
    case ITINERARY_STATE.ESTIMATE:
    case ITINERARY_STATE.QUOTING:
    case ITINERARY_STATE.HOLDING:
    case ITINERARY_STATE.CANCELLED:
      return false;
    case ITINERARY_STATE.QUOTE:
    case ITINERARY_STATE.PROVISIONAL:
    case ITINERARY_STATE.CONFIRM_REQUESTED:
    case ITINERARY_STATE.UNKNOWN:
    case ITINERARY_STATE.CONFIRMING:
    case ITINERARY_STATE.CONFIRMED:
      return true;
    default:
      log.warn('Unknown itinerary state:', state);
      return false;
  }
};

export const itineraryStateLocksPrice = state => {
  switch (state) {
    case ITINERARY_STATE.EXAMPLE:
    case ITINERARY_STATE.SAVED:
    case ITINERARY_STATE.DRAFT:
    case ITINERARY_STATE.ESTIMATE:
    case ITINERARY_STATE.QUOTING:
    case ITINERARY_STATE.QUOTE:
    case ITINERARY_STATE.HOLDING:
    case ITINERARY_STATE.CANCELLED:
      return false;
    case ITINERARY_STATE.PROVISIONAL:
    case ITINERARY_STATE.CONFIRM_REQUESTED:
    case ITINERARY_STATE.UNKNOWN:
    case ITINERARY_STATE.CONFIRMING:
    case ITINERARY_STATE.CONFIRMED:
      return true;
    default:
      log.warn('Unknown itinerary state:', state);
      return false;
  }
};

// Helper to deal with legacy setting to empty when no issues or errors
const isEmpty = array => array === '' || size(array) === 0;

export const hasIssues = itinerary => !isEmpty(get(itinerary, 'itinerary.issues'));
export const hasErrors = itinerary => !isEmpty(get(itinerary, 'itinerary.errors'));
export const hasProblems = (itinerary, agency) => {
  let problems = itinerary?.problems;
  if (agency && agency.hasProblems) {
    problems = agency.hasProblems(itinerary);
  }
  if (!itinerary) return true;

  if (hasItineraryFlag(itinerary, ITINERARY_FLAG.SUPPRESS_PROBLEM_NEGATIVE_MARGIN)) {
    if (problems) delete problems.margins;
  }
  return size(itinerary?.problems) > 0;
};

export const hasValidStartDate = itinerary => {
  const startDateValue = get(itinerary, 'itinerary.startDate');
  const startDate = moment(startDateValue);
  const ret = !isUndefined(startDateValue) && startDate.isValid() && startDate.isSameOrAfter(moment(), 'day');
  log.debug('hasValidStartDate:', ret, ' => ', startDateValue, startDate.isValid(), startDate.isSameOrAfter(moment(), 'day'));
  return ret;
};

export const canHold = (itinerary, agency) => {
  const ret = !hasProblems(itinerary, agency) && hasValidStartDate(itinerary);
  log.debug('canHold:', ret, ' => ', !hasProblems(itinerary), hasValidStartDate(itinerary));
  return ret;
};

export const isPaidInFull = itinerary => {
  // @TODO: simplify logic
  if (itinerary && itinerary.payments) {
    let pendingPayment = find(get(itinerary, 'payments', []), { status: PAYMENT_STATUS.PENDING });
    if (pendingPayment) {
      return false;
    }
  }
  // @TODO: dry out against packages/agent/models/Itinerary.js
  let amount = invoiceTotalOrSell(itinerary);
  return get(itinerary, 'finance.paid', 0) + get(itinerary, 'finance.discount', 0) + get(itinerary, 'finance.serviceFee', 0) >= amount;
};

export const invoiceTotal = itinerary => {
  let total = 0;
  if (itinerary && itinerary.payments && itinerary.payments.length > 0) {
    total = itinerary.payments.reduce((total, payment) => {
      total += payment.amount + payment.serviceFee + -1 * (payment.discount ?? 0);
      return total;
    }, 0);
  }
  return total;
};

export const invoiceTotalOrSell = itinerary => {
  let amount;
  if (get(itinerary, 'finance.sellingPrice')) {
    amount = get(itinerary, 'finance.sellingPrice', 0);
  } else {
    amount = get(itinerary, 'finance.sell', 0) + get(itinerary, 'finance.sell_fee_credit', 0);
  }
  const invoiced = invoiceTotal(itinerary);
  if (invoiced > 0) {
    amount = invoiced;
  }
  return amount;
};

export const canPay = (itinerary, agency) => {
  const ret =
    getItineraryFlag(itinerary, ITINERARY_FLAG.GENERATE_PAYMENTS) &&
    !isUndefined(itinerary) &&
    itineraryStateCanPay(get(itinerary, 'itinerary.state', ITINERARY_STATE.QUOTE)) &&
    !isPaidInFull(itinerary) &&
    !hasProblems(itinerary, agency);
  log.debug(
    'canPay:',
    ret,
    ' => ',
    getItineraryFlag(itinerary, ITINERARY_FLAG.GENERATE_PAYMENTS),
    !isUndefined(itinerary),
    get(itinerary, 'itinerary.state', ITINERARY_STATE.QUOTE),
    itineraryStateCanPay(get(itinerary, 'itinerary.state', ITINERARY_STATE.QUOTE)),
    !isPaidInFull(itinerary),
    !hasProblems(itinerary, agency),
  );
  return ret;
};

export const canBalance = itinerary => canPay(itinerary) && get(itinerary, 'finance.paid', 0) >= get(itinerary, 'finance.deposit', 0);
export const canDeposit = itinerary => canPay(itinerary) && get(itinerary, 'finance.paid', 0) < get(itinerary, 'finance.deposit', 0);

export const isInFlight = instance =>
  [ITINERARY_STATE.QUOTING, ITINERARY_STATE.HOLDING, ITINERARY_STATE.CONFIRMING].includes(get(instance, 'itinerary.state'));

export const isAtRest = instance => [ITINERARY_STATE.UNKNOWN, ITINERARY_STATE.QUOTE].includes(get(instance, 'itinerary.state'));
