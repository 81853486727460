import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'universal-cookie';

import getItineraryEndpoints from './itinerary';
import getPresentationEditEndpoints from './presentationEdit';
import getMediaContentEndpoints from './mediaContent';
import getAgencyContentEndpoints from './agency';
import getAgentContentEndpoints from './agent';
import getCampEndpoints from './camp';
import getInfoEndpoints from './info';
import getActivityEndpoints from './activity';
import getRegionEndpoints from './region';

const cookies = new Cookies();

let baseUrl = '/api';
if (process.env.NODE_ENV === 'test') {
  baseUrl = 'http://127.0.0.1/api';
}

export const mainApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: headers => {
      const token = cookies.get('TOKEN');
      const masq = cookies.get('MASQ');
      const masqFrom = cookies.get('MASQ_FROM');

      if (token) {
        headers.set('Authorization', token);
      }
      if (masq) {
        headers.set('X-Access-Masq', masq);
      }
      if (masqFrom) {
        headers.set('X-Access-MasqFrom', masqFrom);
      }
      return headers;
    },
  }),
  tagTypes: ['Itinerary', 'PresentationEdit', 'MediaContent', 'Agent', 'Agency', 'Camp'],
  refetchOnMountOrArgChange: true,
  endpoints: build => {
    return {
      ...getItineraryEndpoints(build),
      ...getPresentationEditEndpoints(build),
      ...getMediaContentEndpoints(build),
      ...getAgencyContentEndpoints(build),
      ...getAgentContentEndpoints(build),
      ...getCampEndpoints(build),
      ...getInfoEndpoints(build),
      ...getActivityEndpoints(build),
      ...getRegionEndpoints(build),
    };
  },
});

export const {
  // itinerary hooks
  useGetItineraryQuery,
  usePatchItineraryMutation,
  usePutItineraryMutation,
  useGetPosterQuery,
  useGetFeaturedImagesForItineraryQuery,
  useAssembleVideoMutation,

  // presentationEdit hooks
  useGetPresentationEditByItineraryQuery,
  useGetPresentationEditByClientQuery,
  useCreateOrUpdatePresentationEditMutation,
  usePostPresentationEditMutation,
  usePatchPresentationEditMutation,
  useRenderDataItineraryQuery,

  // mediaContent hooks
  useGetMediaContentForItineraryQuery,
  useGetMediaContentForClientQuery,
  useUpdateMediaContentMutation,
  useUploadMediaMutation,
  useUploadToS3Mutation,
  useDeleteMediaContentMutation,

  // agency hooks
  useGetAgencyQuery,
  usePatchAgencyMutation,

  // agent hooks
  useGetAgentQuery,
  usePatchAgentMutation,
  useGetShareVideoLinkMutation,
  useLogoutMutation,

  // camp hooks
  useGetCampQuery,
  useGetAccommodationQuery,

  // info hooks
  useGetWhoAmIQuery,

  // activities hooks
  useGetActivityCarouselQuery,

  // region hooks
  useGetRegionQuery,
} = mainApi;
