import { useQuery } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';

export const useItineraryUpdateAndRequoteApi = ({ itineraryId, itinerary }) => {
  const Api = useApi();
  return useQuery({
    queryKey: ['update-requote', itinerary.id], // something more?
    queryFn: async () => {
      const response = await Api.itineraryUpdateAndRequote(itineraryId, itinerary);
      return response.data || {};
    },
    staleTime: 0, // http.post
    //enabled, // Turn this on/off?
  });
};

export function useVersionHistoryQuery(id) {
  const Api = useApi();
  return useQuery({
    queryKey: ['itinerary', id, 'version-history'],
    queryFn: async () => {
      const response = await Api.getItineraryEvents(id);
      return response?.reverse?.();
    },
    staleTime: 6000,
    cacheTime: 6000,
  });
}
