import { forwardRef } from 'react';

export default forwardRef(function FlagMark({ className }, ref) {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      className={className}
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      data-test-id="svg-flag-icon"
    >
      <path d="M1.72607 1H15.7261V19L8.72607 15.9143L1.72607 18.8138V1Z" fill="white" fillOpacity="0.6" />
      <path
        d="M5.86893 12.2224H7.58322V8.88915H12.7261L11.0118 6.66698L12.7261 4.44481H5.86893V12.2224ZM0.726076 20V2.22265L0.726074 0.000202452L3.01179 0.000475733H14.4404C14.7621 0.000475733 15.2037 0.000351066 15.6347 0.000229368C16.0457 0.000113334 16.4472 0 16.7261 0L16.7261 2.22265V20L8.72608 16.6667L0.726076 20ZM1.75467 18.5L8.72608 15.401L15.6407 18.5L15.6407 1H1.75467V18.5Z"
        fill="currentColor"
      />
      <path
        d="M5.86893 12.2224H7.58322V8.88915H12.7261L11.0118 6.66698L12.7261 4.44481H5.86893V12.2224ZM0.726076 20V2.22265L0.726074 0.000202452L3.01179 0.000475733H14.4404C14.7621 0.000475733 15.2037 0.000351066 15.6347 0.000229368C16.0457 0.000113334 16.4472 0 16.7261 0L16.7261 2.22265V20L8.72608 16.6667L0.726076 20ZM1.75467 18.5L8.72608 15.401L15.6407 18.5L15.6407 1H1.75467V18.5Z"
        fill="currentColor"
      />
    </svg>
  );
});
