import React from 'react';
import * as ItrvlIcons from './ItrvlIcons';
import { startCase, upperFirst } from 'lodash';

import logger from 'itrvl-logger';
const log = logger(__filename);

const ItrvlIcon = ({ name, alt, ...iconProps }) => {
  if (!name) {
    log.warn('No name was supplied for an icon');
    return null;
  }
  const iconName = upperFirst(name);
  const Icon = ItrvlIcons[iconName];
  if (!Icon) {
    log.warn(`Icon of type ${iconName} not found`);
    return null;
  }
  // Override with color="", style{fontSize:''}.
  return (
    <Icon
      width="1em"
      height="1em"
      stroke="currentColor"
      fill="currentColor"
      alt={alt || startCase(name)}
      role="img"
      aria-label={alt}
      {...iconProps}
    />
  );
};

export default ItrvlIcon;
