import { useApi } from 'common/hooks/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useUser } from 'common/hooks/user';
import { getAgencyIdForPatch } from './utils';
import { response } from 'msw';

const CACHE_KEYS = {
  ACTIVITIES: 'ACTIVITIES',
  LIST: 'LIST',
};

export const useActivitiesListQuery = ({ filters = {}, enabled = true }) => {
  const Api = useApi();
  return useQuery({
    queryKey: [CACHE_KEYS.ACTIVITIES, CACHE_KEYS.LIST, filters],
    queryFn: async () => {
      const response = await Api.getAgencyActivities(filters);
      return response?.data ?? [];
    },
    retry: (_failureCount, error) => {
      if (error?.response?.status >= 400) return false;
      return 3;
    },
    cacheTime: 0, // There has to be a better solution, the invalidate query in patch were still keeping data from this query
    enabled,
  });
};

export const useActivityNew = props => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ payload }) => {
      const response = await api.createActivity(payload);
      await queryClient.invalidateQueries({ queryKey: [CACHE_KEYS.ACTIVITIES, CACHE_KEYS.LIST] });
      return response?.data;
    },
    onSuccess: data => {
      props?.onSuccess?.(data);
    },
    onError: error => {
      props?.onError?.(error);
    },
  });
};

export const useActivityEditsUpsert = props => {
  const Api = useApi();
  const { user } = useUser();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, payload, showToast }) => {
      const response = await Api.upsertActivityPatchEdits(id, {
        ...payload,
        agencyId: getAgencyIdForPatch(user),
      });
      await queryClient.invalidateQueries({ queryKey: [CACHE_KEYS.ACTIVITIES, CACHE_KEYS.LIST] });
      return { ...response?.data, showToast };
    },
    onSuccess: data => {
      props?.onSuccess?.(data);
    },
    onError: () => {
      props?.onError?.();
    },
  });
};

export const useActivityEditsPatch = props => {
  const Api = useApi();
  const { user } = useUser();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, payload, showToast }) => {
      await Api.patchActivityPatchEdits(id, {
        ...payload,
        agencyId: getAgencyIdForPatch(user),
      });
      await queryClient.invalidateQueries({ queryKey: [CACHE_KEYS.ACTIVITIES, CACHE_KEYS.LIST] });
      return { ...response?.data, showToast };
    },
    onSuccess: data => {
      props?.onSuccess?.(data);
    },
    onError: () => {
      props?.onError?.();
    },
  });
};
