import { forwardRef } from 'react';

export default forwardRef(function Bullseye({ color, className }, ref) {
  return (
    <svg viewBox="0 0 26 26" className={className} ref={ref} role="img" aria-label="bullseye">
      <path
        d="M16.375 13.25C16.375 15.1133 14.8633 16.625 13 16.625C11.1016 16.625 9.625 15.1133 9.625 13.25C9.625 11.3867 11.1016 9.875 13 9.875C14.8633 9.875 16.375 11.3867 16.375 13.25ZM13 11C11.7344 11 10.75 12.0195 10.75 13.25C10.75 14.5156 11.7344 15.5 13 15.5C14.2305 15.5 15.25 14.5156 15.25 13.25C15.25 12.0195 14.2305 11 13 11ZM13 4.25C13.2812 4.25 13.5625 4.53125 13.5625 4.8125V6.53516C16.832 6.81641 19.4336 9.41797 19.7148 12.6875H21.4375C21.7188 12.6875 22 12.9688 22 13.25C22 13.5664 21.7188 13.8125 21.4375 13.8125H19.7148C19.4336 17.1172 16.832 19.7188 13.5625 20V21.6875C13.5625 22.0039 13.2812 22.25 13 22.25C12.6836 22.25 12.4375 22.0039 12.4375 21.6875V20C9.13281 19.7188 6.53125 17.1172 6.25 13.8125H4.5625C4.24609 13.8125 4 13.5664 4 13.25C4 12.9688 4.24609 12.6875 4.5625 12.6875H6.25C6.53125 9.41797 9.13281 6.81641 12.4375 6.53516V4.8125C12.4375 4.53125 12.6836 4.25 13 4.25ZM7.375 13.25C7.375 16.3789 9.87109 18.875 13 18.875C16.0938 18.875 18.625 16.3789 18.625 13.25C18.625 10.1562 16.0938 7.625 13 7.625C9.87109 7.625 7.375 10.1562 7.375 13.25Z"
        fill={color || 'currentColor'}
      />
    </svg>
  );
});
