import { useQuery } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';
import Itinerary from 'models/Itinerary';

export const useFetchItinerary = () => {
  const Api = useApi();
  return {
    fetch: async id => {
      const response = await Api.getItinerary(id, {
        include: [
          'agent',
          'client',
          'payments',
          'messages',
          {
            parties: [
              {
                travelers: ['client'],
              },
            ],
          },
        ],
      });
      return new Itinerary(response?.data);
    },
  };
};

export const useItineraryQuery = id => {
  const fetchItinerary = useFetchItinerary(id);
  const query = useQuery({
    queryKey: ['itinerary', id],
    queryFn: async () => {
      const response = await fetchItinerary.fetch(id);
      return response;
    },
    retry: (_failureCount, error) => {
      // this is axios specific handling, but lets not retry on a 404;
      if (error?.response?.status === 404) return false;
      return true;
    },
    cacheTime: 0,
  });
  return query;
};

export const useActivitiesBySupplierQuery = ({ segment }) => {
  const { supplierCode, startDate, endDate } = segment;
  const limit = 0;

  const Api = useApi();
  const query = useQuery({
    queryKey: ['activities', { supplierCode, limit, startDate, endDate }],
    queryFn: async () => {
      if (!supplierCode) return null; // Bailout for hook. aka: enabled
      const response = await Api.getActivities([supplierCode], limit, startDate, endDate);
      return response?.data?.[supplierCode];
    },
    retry: (_failureCount, error) => {
      // this is axios specific handling, but lets not retry on a 404;
      if (error?.response?.status === 404) return false;
      return true;
    },
    cacheTime: 0,
  });
  return query;
};

export const useActivitiesByLocationQuery = ({ segment, options = {} }) => {
  const { countryCode, locationCode, startDate, endDate } = segment;
  const limit = 0;
  const includeImages = false; // Really includeImage (singular).

  const Api = useApi();
  const query = useQuery({
    queryKey: ['activities', { locationCode, limit, includeImages, startDate, endDate, options }],
    queryFn: async () => {
      if (!locationCode) return null; // Bailout for hook.
      const response = await Api.getActivitiesByLocation(
        locationCode,
        options.useCountryCode ? countryCode : '',
        limit,
        includeImages,
        startDate,
        endDate,
      );
      return response?.data;
    },
    retry: (_failureCount, error) => {
      // this is axios specific handling, but lets not retry on a 404;
      if (error?.response?.status === 404) return false;
      return true;
    },
    cacheTime: 0,
    enabled: Boolean(options.enabled),
  });
  return query;
};

export const useActivitiesByCountryQuery = ({ segment, options = {} }) => {
  const { countryCode, locationCode, supplierCode, startDate, endDate } = segment;
  const limit = 0;

  const Api = useApi();
  const query = useQuery({
    queryKey: ['activities', { countryCode, locationCode, limit, startDate, endDate }],
    queryFn: async () => {
      if (!countryCode) return null; // Bailout for hook.
      const response = await Api.getActivitiesByCountry(countryCode, locationCode, supplierCode, limit, startDate, endDate, options);
      return response?.data;
    },
    retry: (_failureCount, error) => {
      // this is axios specific handling, but lets not retry on a 404;
      if (error?.response?.status === 404) return false;
      return true;
    },
    cacheTime: 0,
    enabled: Boolean(options.enabled),
  });
  return query;
};
