import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useModal } from '@ebay/nice-modal-react';
import clsx from 'clsx';
import { isArray, map, merge } from 'lodash';

import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import Button from 'components/v2/Button';
import CloseIcon from '@mui/icons-material/Close';
import AsyncButton from 'components/v2/AsyncButton';
import { noop } from 'common';

const GUTTER = 50;
const useStyles = makeStyles(theme => ({
  header: {
    position: 'relative',
    padding: GUTTER,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    '& h1': {
      margin: 0,
      fontFamily: 'Athelas',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: 1.333333333333333,
      letterSpacing: '0.04em',
      textTransform: 'uppercase',
      // @todo: var me?
      color: '#221F20',
    },
    '& .baseModalSubtext': {
      display: 'block',
      fontSize: 14,
      lineHeight: 1.4285714286,
    },
  },
  content: {
    background: theme.palette.stoneGrey.white60,
    padding: `${GUTTER}px ${GUTTER}px 23px ${GUTTER}px`,
    display: 'flex',
    flexDirection: 'column',
    gap: 23,
    // specificity for MuiDialogTitle+MuiDialogContent
    '&.baseModalChildren': {
      padding: `${GUTTER / 2}px ${GUTTER}px 23px ${GUTTER}px`,
    },
  },
  footer: {
    background: theme.palette.stoneGrey.white60,
    padding: '25px 50px 50px 50px',
  },
  controlWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(),
    justifyContent: 'space-between',
  },
  controls: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
  },
  close: {
    position: 'absolute',
    top: 20,
    right: 20,
    lineHeight: 1,
    cursor: 'pointer',
    '& svg': {
      fontSize: 18,
    },
  },
}));

export default function BaseModal(props) {
  const {
    title,
    subtext,
    showHide = true,
    children,
    controls,
    fullWidth,
    maxWidth = false,
    disableHide = false,
    paperProps = {},
    // control props
    showControls = true,
    loading,
    // deny
    denyText,
    onDeny,
    deniedDisabled,
    deniedProps = {},
    // confirm
    confirmText,
    onConfirm,
    confirmDisabled,
    confirmedProps = {},
    // delete
    deleteText,
    onDelete,
    deleteDisable,
    deleteProps = {},
    labelledById = 'dialog-title', // this is for the initial modal, overwrite for nested or stacked modals
    titleExtra,
    ...rest
  } = props;
  const buttonConfirmText = confirmText || confirmedProps.text || 'Ok';
  const modal = useModal();
  const classes = useStyles(props);

  return (
    <Dialog
      open={modal.visible}
      onClose={() => (disableHide ? noop() : modal.hide())}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      TransitionProps={{
        // tear down modal so state is fresh on remount
        onExited: modal.remove,
      }}
      PaperProps={merge({}, { 'data-test-id': title }, paperProps)}
      aria-labelledby={labelledById}
      {...rest}
    >
      <DialogTitle id="" component="header" className={classes.header}>
        <div>
          {title && isArray(title) ? (
            map(title, t => (
              <h1 id={labelledById} key={t}>
                {t}
              </h1>
            ))
          ) : (
            <h1 id={labelledById}>{title}</h1>
          )}
          {subtext && isArray(subtext) ? (
            map(subtext, s => (
              <span className="baseModalSubtext" data-test-id="modal-description" key={s}>
                {s}
              </span>
            ))
          ) : (
            <Typography component="span" className="baseModalSubtext">
              {subtext}
            </Typography>
          )}
        </div>
        {showHide && (
          <div className={clsx('hoverable', classes.close)} onClick={modal.hide} role="button" aria-label="close modal">
            <CloseIcon />
          </div>
        )}
        {titleExtra}
      </DialogTitle>
      {children && (
        <DialogContent className={clsx('baseModalChildren', classes.content)} aria-label="content" data-test-id="content">
          {children}
        </DialogContent>
      )}
      {/* @todo: lets use these */}
      {/* <DialogActions> */}
      {/* @todo: probably provide default controls? but allow overrides */}
      {showControls ? (
        controls ? (
          <DialogActions className={classes.footer}>{controls}</DialogActions>
        ) : (
          <DialogActions className={clsx(classes.footer, classes.controlWrapper, 'footer-controls')}>
            {onDelete && (
              <Button variant="contained" color="error" onClick={onDelete} disabled={deleteDisable} {...deleteProps}>
                {deleteText ?? deleteProps?.text ?? 'Delete'}
              </Button>
            )}
            <div className={clsx(classes.controls, 'footer-controls')}>
              <Button onClick={onDeny ? () => onDeny(modal) : modal.hide} disabled={deniedDisabled} {...deniedProps}>
                {denyText || deniedProps.text || 'Cancel'}
              </Button>
              <AsyncButton
                onClick={loading ? noop : onConfirm ? () => onConfirm(modal) : modal.hide}
                loading={loading}
                color="primary"
                variant="contained"
                disabled={confirmDisabled || confirmedProps.disabled}
                className={classes.confirmButton}
                overlay
                {...confirmedProps}
                aria-label={buttonConfirmText}
              >
                {buttonConfirmText}
              </AsyncButton>
            </div>
          </DialogActions>
        )
      ) : (
        <></>
      )}
    </Dialog>
  );
}
